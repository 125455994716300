.top-nav .btn {
  margin: 0 10px;
}

.slick-slide img {
  display: block;
  margin: 0 auto;
  max-height: 400px;
  object-fit: cover;
}

.recommendation {
  background-color: #f9f9f9;
  border-radius: 5px;
  min-height: 150px;
}

.map-responsive iframe {
  display: block;
  height: 60vh;
  width: 80%;
  margin: auto;
  border-radius: 10px;
}

.navbar-light .navbar-toggler {
  border: none;
  outline: none;
}

.navbar-toggler:focus,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.nav-btn {
  margin: 0 5px;
}

/* Hide buttons on small screens */
@media (max-width: 300px) {
  .nav-btn {
    display: none;
  }
}

@media (max-width: 992px) {
  .navbar-nav {
    flex-direction: column;
    direction: rtl;
  }

  .navbar-nav .nav-link {
    margin-bottom: 10px;
  }
}
